import { FC, useState } from 'react';
import Link from 'next/link';
import ImgFullLabel from '../img-full-label/ImgFullLabel';
import { getTruncatedTxt, getAuthorFullName } from '@/helpers/helpers';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { hideImgOnError } from '@/helpers/helpers';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import styles from './HomePopularBooks.module.scss';

interface IHomePopularBooks {
  popularBooks: IBookPage;
  onePopularBook: IBooksPageData;
}

const HomePopularBooks: FC<IHomePopularBooks> = ({ popularBooks, onePopularBook }) => {
  const [isMobileShow, setIsMobileShow] = useState(false);

  return (
    <>
      {popularBooks.data.length > 0 && (
        <>
          <div className="contentHeader">
            <div className="contentHeader__item">
              <b>Топ 20 лучших книг</b>
            </div>
          </div>
          <div className="content">
            <div className={isMobileShow ? `${styles.popularBooks} ${styles.popularBooks_open}` : `${styles.popularBooks}`}>
              {onePopularBook.alias !== undefined && (
                <div className={`${styles.popularBooks__item} ${styles.popularBooks__item_full}`}>
                  <div className={styles.popularBooks__wrap}>
                    <div className={styles.popularBooks__itemImgWrap}>
                      <Link prefetch={false} href={`/authors/${onePopularBook.author.alias}/${onePopularBook.alias}`} className={styles.popularBooks__itemImg}>
                        <img className={styles.popularBooks__img} src={`${DEV_ABSOLUTE_URL}/covers/${onePopularBook.book.external_id}.jpg`} alt="img" onError={hideImgOnError} width={76} height={110} />
                        {onePopularBook.book.allow_full_free > 0 && <ImgFullLabel />}
                      </Link>
                    </div>
                    <div className={styles.popularBooks__desc}>
                      <div className={styles.popularBooks__titleWrap}>
                        <Link prefetch={false} href={`/authors/${onePopularBook.author.alias}/${onePopularBook.alias}`} className={styles.popularBooks__title}>
                          {onePopularBook.book.title}
                        </Link>
                        <div className={`${styles.popularBooks__visited} ${styles.popularBooks__visited_full}`}>40{onePopularBook.views_count}</div>
                      </div>
                      <div>
                        <Link prefetch={false} href={`/authors/${onePopularBook.author.alias}`} className={styles.popularBooks__link}>
                          {getAuthorFullName(onePopularBook.author.first_name, onePopularBook.author.middle_name, onePopularBook.author.last_name)}
                        </Link>
                        <Link prefetch={false} href={`/genres/${onePopularBook.book.genres[0].alias}`} className={styles.popularBooks__link}>
                          {onePopularBook.book.genres[0].title}
                        </Link>
                        <div className={`${styles.popularBooks__visited} ${styles.popularBooks__visited_mob}`}>40{onePopularBook.views_count}</div>
                        <span className={styles.popularBooks__txt}>{getTruncatedTxt(onePopularBook.book.description, 180)}</span>
                      </div>
                    </div>
                  </div>
                  <span className={`${styles.popularBooks__txt} ${styles.popularBooks__txt_mob}`}>{getTruncatedTxt(onePopularBook.book.description, 180)}</span>
                </div>
              )}

              <>
                {popularBooks.data.map((book, index) => (
                  <div key={book.id} className={index > 3 ? `${styles.popularBooks__item} ${styles.popularBooks__item_mob}` : `${styles.popularBooks__item}`}>
                    <div className={styles.popularBooks__wrap}>
                      <div className={styles.popularBooks__itemImgWrap}>
                        <Link prefetch={false} href={`/authors/${book.author.alias}/${book.alias}`} className={styles.popularBooks__itemImg}>
                          <img className={styles.popularBooks__img} src={`${DEV_ABSOLUTE_URL}/covers/${book.book.external_id}.jpg`} alt="img" onError={hideImgOnError} width={76} height={110} />
                          {book.book.allow_full_free > 0 && <ImgFullLabel />}
                        </Link>
                      </div>
                      <div className={styles.popularBooks__desc}>
                        <div className={styles.popularBooks__titleWrap}>
                          <Link prefetch={false} href={`/authors/${book.author.alias}/${book.alias}`} className={styles.popularBooks__title}>
                            {book.book.title}
                          </Link>
                        </div>
                        <div>
                          <Link prefetch={false} href={`/authors/${book.author.alias}`} className={styles.popularBooks__link}>
                            {getAuthorFullName(book.author.first_name, book.author.middle_name, book.author.last_name)}
                          </Link>
                          <Link prefetch={false} href={`/genres/${book.book.genres[0].alias}`} className={styles.popularBooks__link}>
                            {book.book.genres[0].title}
                          </Link>
                          <span className={styles.popularBooks__visited}>{book.views_count}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            </div>

            <div className={styles.topBooksLinks}>
              {!isMobileShow && (
                <div className="topBooksLinks__openList" onClick={() => setIsMobileShow(true)}>
                  Полный список
                </div>
              )}
              <Link prefetch={false} className="allBooksLink allBooksLink_top" href="/tops">
                Все топы библиотеки
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default HomePopularBooks;
