import { FC } from 'react';
import Link from 'next/link';
import { parseISO, format } from 'date-fns';
import ImgFullLabel from '../img-full-label/ImgFullLabel';
import { getTruncatedTxt, getAuthorFullName } from '@/helpers/helpers';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { hideImgOnError } from '@/helpers/helpers';
import { DEV_ABSOLUTE_URL } from '@/helpers/variables';
import styles from './HomeNewBooks.module.scss';

interface IHomeNewBooks {
  newBooks: IBookPage;
  oneNewBook: IBooksPageData;
}

const HomeNewBooks: FC<IHomeNewBooks> = ({ newBooks, oneNewBook }) => {
  return (
    <>
      {newBooks.data.length > 0 && (
        <>
          <div className="contentHeader">
            <div className="contentHeader__item">
              <b>Новые книги на сайте</b>
            </div>
          </div>
          <div className="content">
            <div className={styles.newBooks}>
              <div className={`${styles.newBooks__item} ${styles.newBooks__item_full}`}>
                <div className={styles.newBooks__wrap}>
                  <div>
                    <div className={styles.newBooks__itemImgWrap}>
                      <Link prefetch={false} href={`/authors/${oneNewBook.author.alias}/${oneNewBook.alias}`} className={styles.newBooks__itemImg}>
                        <img className={styles.newBooks__img} src={`${DEV_ABSOLUTE_URL}/covers/${oneNewBook.book.external_id}.jpg`} alt="img" onError={hideImgOnError} width={76} height={110} />
                        {oneNewBook.book.allow_full_free > 0 && <ImgFullLabel />}
                      </Link>
                    </div>
                  </div>

                  <div className={styles.newBooks__desc}>
                    <div className={styles.newBooks__titleWrap}>
                      <Link prefetch={false} href={`/authors/${oneNewBook.author.alias}/${oneNewBook.alias}`} className={styles.newBooks__title}>
                        {oneNewBook.book.title}
                      </Link>
                      <div className={styles.newBooks__date}>
                        <time dateTime={String(oneNewBook.created_at)}>{format(parseISO(String(oneNewBook.created_at)), 'dd.MM.yyyy HH:mm')}</time>
                      </div>
                    </div>
                    <div>
                      <Link prefetch={false} href={`/authors/${oneNewBook.author.alias}`} className={styles.newBooks__link}>
                        {getAuthorFullName(oneNewBook.author.first_name, oneNewBook.author.middle_name, oneNewBook.author.last_name)}
                      </Link>
                      <Link prefetch={false} href={`/genres/${oneNewBook.genres[0].alias}`} className={styles.newBooks__link}>
                        {oneNewBook.book.genres[0].title}
                      </Link>
                      <span className={styles.newBooks__txt}>{getTruncatedTxt(oneNewBook.book.description, 180)}</span>
                    </div>
                  </div>
                </div>
                <span className={`${styles.newBooks__txt} ${styles.newBooks__txt_mob}`}>{getTruncatedTxt(oneNewBook.book.description, 180)}</span>
              </div>

              <>
                {newBooks.data.map((book) => (
                  <div key={book.id} className={styles.newBooks__item}>
                    <div className={styles.newBooks__wrap}>
                      <div>
                        <Link prefetch={false} href={`/authors/${book.author.alias}/${book.alias}`} className={styles.newBooks__itemImg}>
                          <img className={styles.newBooks__img} src={`${DEV_ABSOLUTE_URL}/covers/${book.book.external_id}.jpg`} alt="img" onError={hideImgOnError} width={76} height={110} />
                          {book.book.allow_full_free > 0 && <ImgFullLabel />}
                        </Link>
                      </div>

                      <div className={styles.newBooks__desc}>
                        <div className={styles.newBooks__titleWrap}>
                          <Link prefetch={false} href={`/authors/${book.author.alias}/${book.alias}`} className={styles.newBooks__title}>
                            {book.book.title}
                          </Link>
                          <div className={styles.newBooks__date}>
                            <time dateTime={String(book.created_at)}>{format(parseISO(String(oneNewBook.created_at)), 'dd.MM.yyyy HH:mm')}</time>
                          </div>
                        </div>
                        <div>
                          <Link prefetch={false} href={`/authors/${book.author.alias}`} className={styles.newBooks__link}>
                            {getAuthorFullName(book.author.first_name, book.author.middle_name, book.author.last_name)}
                          </Link>
                          <Link prefetch={false} href={`/genres/${book.book.genres[0].alias}`} className={styles.newBooks__link}>
                            {book.book.genres[0].title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            </div>
            <Link prefetch={false} className="allBooksLink" href="/new_books">
              Полный список новинок
            </Link>
          </div>
        </>
      )}
    </>
  );
};
export default HomeNewBooks;
